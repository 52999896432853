<template>
  <div class="signature-card">
    <div class="content">
      <span class="fullname">
        {{ fullName }}
      </span>
      <span class="information">
        {{ information }}
      </span>
      <span class="booking-reference" v-if="bookingReference">
        <span class="booking-reference__gray">Booking reference</span>
        <span>{{ bookingReference }}</span>
      </span>
    </div>
    <div class="image-wrapper">
      <img v-if="image" :src="image" alt="" />
    </div>
    <div class="signature-card__walkinLabel" v-if="isWalkIn">walk-in</div>
  </div>
</template>

<script>
export default {
  name: "SignatureCard",
  props: {
    image: {
      type: String,
      default: "",
    },
    fullName: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    bookingReference: {
      type: [String, Number],
    },
    isWalkIn: {
      type: Boolean,
    },
  },
  computed: {
    information() {
      return [this.date || [], this.email || []].flat().join(" | ");
    },
  },
};
</script>

<style lang="scss" scoped>
.signature-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  box-shadow: 0px 8px 60px 0px rgba(0, 46, 131, 0.06);
  border-radius: 8px;
  gap: 8px;
  min-height: 132px;
  cursor: pointer;

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;

    .fullname {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: $secondary-500;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .information {
      color: $primary;
      font-size: 14px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .booking-reference {
      display: flex;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      gap: 4px;
      &__gray {
        color: #b0b8c7;
      }
    }
  }

  .image-wrapper {
    width: 56px;
    height: 56px;
    background-color: #c2cee330;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 4px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__walkinLabel {
    position: absolute;
    top: 0;
    right: 0;
    text-transform: uppercase;
    color: $secondary-500;
    padding: 4px 16px;
    border-radius: 0px 8px;
    background-color: $yellow;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 200% */
  }
}
</style>
