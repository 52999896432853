<template>
  <div class="signatures-list">
    <SignatureCard
      v-for="signature in signatures"
      :key="signature.id"
      :image="signature.signature"
      :full-name="getName(signature)"
      :email="getEmail(signature)"
      :date="getDate(signature)"
      :bookingReference="signature?.bookingReference"
      :isWalkIn="!signature.bookingId"
      @click.native="$emit('click', signature)"
    />
  </div>
</template>
<script>
import SignatureCard from "@/components/signatures/SignatureCard";
import moment from "moment";
import { getTimezoneOffsetHours } from "@/helpers/utils";
import { mapState } from "vuex";

export default {
  name: "SignaturesList",
  components: { SignatureCard },
  props: {
    signatures: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
    }),
  },
  methods: {
    getEmail(signature) {
      return signature.fields.email?.value || "";
    },
    getName(signature) {
      const firstName = signature.fields?.firstName?.value || "";
      const lastName = signature.fields?.lastName?.value || "";
      return `${firstName} ${lastName}`;
    },
    getDate(signature) {
      const dateMoment = moment.utc(signature.createdAt.seconds, "X");
      return dateMoment
        .add(getTimezoneOffsetHours(this.venue.timezone), "h")
        .format("MMM D, YYYY");
    },
  },
};
</script>
