<template>
  <div class="signaturesBookingPage">
    <back-title @click="goBack">{{ $t("Signed Waivers") }}</back-title>
    <Loader v-if="isLoading" color="primary" size="m" class="mx-auto" />
    <p v-else-if="isError">
      {{ $t("An error occurred") }}. {{ $t("repeat the request later") }}
    </p>
    <div v-else-if="signatures.length" class="signaturesBookingPage__content">
      <SignaturesList
        class="grid-2"
        :signatures="signatures"
        @click="handleCardClick"
      />
    </div>
    <div v-else class="empty">
      {{ $t("You have no signed waivers yet") }}
    </div>
    <Guard permission="signatures.create" v-slot="{ isAvailable }">
      <div class="signaturesBookingPage__actions mt-auto">
        <Button
          v-if="isAvailable"
          variant="primary"
          is-small
          :is-block="['xs', 'sm'].includes($mq)"
          @click="handleSignClick"
        >
          {{ $t("Sign Waiver") }}
        </Button>
        <Button
          v-if="signatures && signatures.length"
          variant="secondary"
          is-small
          is-outlined
          :is-block="['xs', 'sm'].includes($mq)"
          @click="handleSignaturesExport"
        >
          {{ $t("Raw export to CSV") }}
        </Button>
      </div>
    </Guard>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle";
import { mapActions, mapState } from "vuex";
import SignaturesList from "@/components/signatures/SignaturesList";
import Guard from "@/components/common/Guard";

export default {
  name: "BookingSignaturesPage",
  components: { Guard, SignaturesList, BackTitle },
  data: () => ({
    isLoading: false,
    isError: false,
  }),
  computed: {
    ...mapState({
      booking: (state) => state.bookings.editedItem,
      signatures: (state) => state.bookings.signatures,
    }),
  },
  async created() {
    try {
      this.isLoading = true;
      if (!this.booking) await this.getBooking(this.$route.params.id);
      await this.getSignatures();
    } catch {
      this.isError = true;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      getBooking: "bookings/fetchBookingById",
      getSignatures: "bookings/fetchSignatures",
      exportSignaturesToCSV: "signatures/exportSignaturesToCSV",
    }),
    goBack() {
      this.$router.push({
        name: "BookingDetail",
      });
    },
    handleCardClick(signature) {
      this.$router.push({
        name: "BookingSignatureDetail",
        params: {
          signatureId: signature.id,
        },
      });
    },
    handleSignClick() {
      this.$router.push({
        name: "BookingSignaturesSign",
      });
    },
    handleSignaturesExport() {
      try {
        this.exportSignaturesToCSV(this.signatures);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style lang="scss">
.signaturesBookingPage {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;

  &__content {
    width: 100%;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $media-laptop) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}
</style>
